import React from 'react';

import { IIconProps } from '../../interfaces/IconInterface';

const LinkIcon = (props: IIconProps) => {
  const { width = 36, className = '', color = '#000' } = props;
  const height = width;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 19.4999C15.6442 20.3611 16.466 21.0737 17.4098 21.5894C18.3536 22.105 19.3973 22.4116 20.47 22.4884C21.5427 22.5653 22.6194 22.4105 23.627 22.0346C24.6347 21.6587 25.5497 21.0705 26.31 20.3099L30.81 15.8099C32.1762 14.3954 32.9321 12.5009 32.9151 10.5344C32.898 8.56796 32.1092 6.68687 30.7186 5.29631C29.3281 3.90575 27.447 3.11698 25.4805 3.09989C23.514 3.0828 21.6195 3.83876 20.205 5.20495L17.625 7.76995"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0001 16.5C20.3559 15.6388 19.5341 14.9262 18.5903 14.4106C17.6465 13.8949 16.6028 13.5883 15.5301 13.5115C14.4574 13.4346 13.3807 13.5894 12.3731 13.9653C11.3654 14.3412 10.4504 14.9294 9.6901 15.69L5.1901 20.19C3.82392 21.6045 3.06796 23.499 3.08505 25.4655C3.10213 27.432 3.8909 29.3131 5.28146 30.7036C6.67202 32.0942 8.55312 32.8829 10.5196 32.9C12.4861 32.9171 14.3806 32.1612 15.7951 30.795L18.3601 28.23"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(LinkIcon);
