import React from 'react';

import { IIconProps } from '../../interfaces/IconInterface';

const PillIcon = (props: IIconProps) => {
  const { width = 48, className = '', color = '#000' } = props;
  const height = width;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H48V48H0V0Z" fill={color} fillOpacity="0.01" />
      <g clipPath="url(#clip0)">
        <rect
          x="31.071"
          y="-0.0415039"
          width="24"
          height="44"
          rx="12"
          transform="rotate(45 31.071 -0.0415039)"
          stroke={color}
          strokeWidth="2"
        />
        <line x1="16.7071" y1="15.2929" x2="32.7071" y2="31.2929" stroke={color} strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="48" height="48" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(PillIcon);
