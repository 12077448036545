import React from 'react';

import { IIconProps } from '../../interfaces/IconInterface';

const SissorsIcon = (props: IIconProps) => {
  const { width = 48, className = '', color = '#000' } = props;
  const height = width;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18L24 4L42 18V40C42 41.0609 41.5786 42.0783 40.8284 42.8284C40.0783 43.5786 39.0609 44 38 44H10C8.93913 44 7.92172 43.5786 7.17157 42.8284C6.42143 42.0783 6 41.0609 6 40V18Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="24" cy="24" r="4" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default React.memo(SissorsIcon);
