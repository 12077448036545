import React from 'react';

import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../components/Buttons/SecondaryButton';
import CalendarSquare from '../components/Icons/CalendarSquare';
import CheckCircle from '../components/Icons/CheckCircle';
import Home from '../components/Icons/Home';
import LinkIcon from '../components/Icons/Link';
import Package from '../components/Icons/Package';
import Pill from '../components/Icons/Pill';
import Scissors from '../components/Icons/Scissors';
import ShieldNoAccent from '../components/Icons/ShieldNoAccent';
import IconTextPair from '../components/IconTextPair/IconTextPair';
import Layout from '../components/layout';
import ReasonCard from '../components/ReasonCard/ReasonCard';
import SEO from '../components/seo';
import { GraphqlImage } from '../utils/IPageProps';

import style from '../styles/pages/index.scss';

import cn from 'classnames';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

interface Props {
  data: {
    twoLadies: GraphqlImage;
    peopleWalking: GraphqlImage;
    manWithLaptop: GraphqlImage;
    building: GraphqlImage;
  };
}

const IndexPage: React.FC<Props> = ({ data }) => {
  const handleButtonClick = () => {
    location.href = 'mailto:kontakt@fanind.pl';
  };

  return (
    <Layout isDarkTheme>
      <SEO title="Home" />
      <section className={style.hero}>
        <div className="container">
          <h1>Marketing geniuses working for you</h1>
          <h3>Upgrade your marketing practices and watch your business soar.</h3>
          <PrimaryButton onClick={handleButtonClick}>Contact Us</PrimaryButton>
        </div>
      </section>
      <section className={style.reasons}>
        <div className="container">
          <h2>
            <small>Reasons</small> What's holding your business back from massive success?
          </h2>
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-3">
              <ReasonCard
                link="solutions"
                icon={<Pill color={style.color2} />}
                description="Is your business aching from lack of traffic, exposure and leads?"
                linkTitle="Gain Exposure"
                className={style.singleInView}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <ReasonCard
                link="solutions"
                icon={<Package color={style.color2} />}
                description="Is your growth plan moving too slow? We'll help you formulate a new growth plan and thrive."
                linkTitle="Create Success Plans"
                className={style.singleInView}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <ReasonCard
                link="solutions"
                icon={<Scissors color={style.color2} />}
                description="Do you need to cut out old marketing strategies and bring in the new? "
                linkTitle="See Tech Solutions"
                className={style.singleInView}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <ReasonCard
                link="solutions"
                icon={<Home color={style.color2} />}
                description="Do you need peace of mind that your business is ranking first on search engines?"
                linkTitle="Get Peace of Mind"
                className={style.singleInView}
              />
            </div>
          </div>
        </div>
      </section>
      <div className={style.images}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-5">
              <Img
                className={style.peopleWalking}
                fluid={data.peopleWalking.childImageSharp.fluid}
                alt="Woman washing her hands with child"
              />
              <Img
                className={style.twoLadies}
                fluid={data.twoLadies.childImageSharp.fluid}
                alt="Low view of a building"
              />
              <Img
                className={cn('d-lg-none', style.building)}
                fluid={data.building.childImageSharp.fluid}
                alt="Low view of a building"
              />
            </div>
            <div className="col-12 col-sm-6 offset-md-1 order-first order-sm-last">
              <Img
                className={style.manWithLaptop}
                fluid={data.manWithLaptop.childImageSharp.fluid}
                alt="Soccer team group huddle"
              />
              <Img
                className={cn('d-none d-lg-block', style.building)}
                fluid={data.building.childImageSharp.fluid}
                alt="Low view of a building"
              />
              <section>
                <h2>Smart, simple loans made just for humans</h2>
                <IconTextPair
                  className={style.singleInView}
                  icon={<ShieldNoAccent />}
                  text="Secure your marketing strategy to set yourself up for massive success"
                />
                <IconTextPair
                  className={style.singleInView}
                  icon={<LinkIcon />}
                  text="AI powered SEO solutions to connect you and your consumers seamlessly"
                />
                <IconTextPair
                  className={style.singleInView}
                  icon={<CalendarSquare />}
                  text="Meet your due dates and success goals on time"
                />
                <IconTextPair
                  className={style.singleInView}
                  icon={<CheckCircle />}
                  text="Lock into a full spectrum marketing strategy for maximum exposure"
                />
              </section>
            </div>
          </div>
        </div>
      </div>
      <section className={style.indexCta}>
        <div className="container">
          <h2>The decision is simple, let's grow your business together!</h2>
          <SecondaryButton onClick={handleButtonClick}>Contact Us</SecondaryButton>
        </div>
      </section>
    </Layout>
  );
};

export const indexQuery = graphql`
  {
    manWithLaptop: file(relativePath: { eq: "Index/index-img1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    peopleWalking: file(relativePath: { eq: "Index/index-img2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    building: file(relativePath: { eq: "Index/index-img3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    twoLadies: file(relativePath: { eq: "Index/index-img4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 460) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
