import React from 'react';

import { IIconProps } from '../../interfaces/IconInterface';

const CheckCircleIcon = (props: IIconProps) => {
  const { width = 36, className = '', color = '#000' } = props;
  const height = width;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 16.62V18C32.9982 21.2347 31.9508 24.3821 30.014 26.9728C28.0773 29.5635 25.3549 31.4588 22.253 32.3759C19.1511 33.293 15.8359 33.1829 12.8017 32.0619C9.76752 30.9409 7.17698 28.8692 5.41644 26.1556C3.6559 23.4421 2.81969 20.2321 3.03252 17.0045C3.24534 13.7769 4.49581 10.7045 6.59742 8.24565C8.69903 5.78677 11.5392 4.07311 14.6943 3.36026C17.8494 2.64741 21.1504 2.97355 24.105 4.29004"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 6L18 21.015L13.5 16.515"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(CheckCircleIcon);
