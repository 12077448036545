import React from 'react';

import { IIconProps } from '../../interfaces/IconInterface';

const ShieldNoAccentIcon = (props: IIconProps) => {
  const { width = 50, className = '', color = '#000' } = props;
  const height = width;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18 33C18 33 30 27 30 18V7.5L18 3L6 7.5V18C6 27 18 33 18 33Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(ShieldNoAccentIcon);
