import React, { MemoExoticComponent } from 'react';

import ChevronIcon from '../../components/Icons/Chevron';
import { IIconProps } from '../../interfaces/IconInterface';

import style from './ReasonCard.scss';

import cn from 'classnames';

interface Props {
  link: string;
  icon: JSX.Element | MemoExoticComponent<(props: IIconProps) => JSX.Element>;
  description: string;
  linkTitle: string;
  className?: string;
}

const ReasonCard: React.FC<Props> = ({ className, icon, description, linkTitle, link }) => (
  <div className={cn(className, style.reasonCard)}>
    {icon}
    <p>{description}</p>
    <a href={link}>
      {linkTitle} <ChevronIcon width={17} />
    </a>
  </div>
);

export default ReasonCard;
