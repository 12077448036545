import React, { ComponentProps } from 'react';

import { LoadingDots } from '../LoadingDots/LoadingDots';

import { Button } from './Button';

import style from './SecondaryButton.scss';

import cn from 'classnames';

export function SecondaryButton({
  children,
  isLoading,
  isDisabled,
  className,
  ...props
}: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      className={cn(className, style.secondary)}
      isLoading={isLoading}
      isDisabled={isDisabled}
    >
      {isLoading ? <LoadingDots /> : children}
    </Button>
  );
}
