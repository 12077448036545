import React, { MemoExoticComponent } from 'react';

import { IIconProps } from '../../interfaces/IconInterface';

import style from './IconTextPair.scss';

import cn from 'classnames';

interface IIconTextPairProps {
  icon: JSX.Element | MemoExoticComponent<(props: IIconProps) => JSX.Element>;
  text: string;
  className?: string;
}

const IconTextPair: React.FC<IIconTextPairProps> = ({
  icon,
  text,
  className = '',
}): JSX.Element => {
  return (
    <div className={cn(style.iconTextPair, className)}>
      {icon}
      <p>{text}</p>
    </div>
  );
};

export default IconTextPair;
